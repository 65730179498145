import { Autocomplete, Box, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { CategorizationDataSourceImpl } from 'src/Data/DataSources/Categorization/CategorizationDataSource';
import { CategorizationRepositoryImpl } from 'src/Data/Repositories/CategorizationRepositoryImpl';
import { useFetchCategorizations } from 'src/Domain/UseCases/useFetchCategorization';
import { SearchContext } from 'src/Presentation/context/SearchContext';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';

interface IndustryValue {
  value: string;
  label: string;
  sector_ref: string;
}

export default function AutocompleteSectorsAndIndustries() {
  const { search, setSearch } = useContext(SearchContext);
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);

  const { categorizations } = useFetchCategorizations(
    new CategorizationRepositoryImpl(new CategorizationDataSourceImpl())
  );

  const sectors: any[] = [];
  categorizations?.forEach((sector) => {
    sectors.push({ label: sector.sector.name, value: sector.sector._id });
    sector.industries.forEach((industryItem) => {
      sectors.push({
        label: industryItem.name,
        value: industryItem._id,
        sector_ref: industryItem.sector_ref,
      });
    });
  });

  const handleOpen = () => {
    setSearch((prevState) => ({
      ...prevState,
      industry: {
        ...prevState.industry,
        openOptions: true,
      },
    }));
  };

  const getTermsByIndustry = (industryId: string): string[] => {
    const foundIndustry = search.industry.terms.find((term) => term.industryID === industryId);
    return foundIndustry ? foundIndustry.terms : [];
  };

  const updateSearch = (industryId: string, industryLabel: string, industrySectorRef: string) => {
    if (industryId !== search.industry.value) {
      const termsByIndustry = getTermsByIndustry(industryId);
      setSearch((prevState) => ({
        ...prevState,
        industry: {
          ...prevState.industry,
          label: industryLabel,
          sector_ref: industrySectorRef,
          value: industryId,
          terms: [
            ...prevState.industry.terms,
            {
              industryID: prevState.industry.value,
              label: prevState.industry.label,
              terms: selectedTerms,
            },
          ],
          openOptions: !search.industry.value,
        },
        page: 1,
        activeTab: undefined,
        terms: termsByIndustry,
      }));

      setSelectedTerms(termsByIndustry);
    }
  };
  const resetSearch = () => {
    setSearch((prevState) => ({
      ...prevState,
      industry: {
        ...prevState.industry,
        label: '',
        sector_ref: '',
        value: '',
        openOptions: true,
      },
      terms: [],
    }));
    setSelectedTerms([]);
  };

  const handleChangeIndustry = (event: React.ChangeEvent<{}>, value: IndustryValue | null) => {
    if (!value) resetSearch();
    else updateSearch(value.value, value.label, value.sector_ref);
  };

  const handleClose = () => {
    setSearch((prevState) => ({
      ...prevState,
      industry: {
        ...prevState.industry,
        openOptions: false,
      },
    }));
  };
  const defaultValue = sectors.find((s) => s.value === search.industry.value) || null;

  useEffect(() => {
    const removeDuplicates = (): typeof search.industry.terms => {
      const uniqueTerms: Record<string, typeof search.industry.terms[0]> = {};
      search.industry.terms.forEach((term) => {
        uniqueTerms[term.industryID] = term;
      });

      return Object.values(uniqueTerms);
    };

    setSearch((prevState) => ({
      ...prevState,
      industry: {
        ...prevState.industry,
        terms: removeDuplicates(),
      },
    }));
  }, [search.industry.value]);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-industry"
      data-cy="selector-sector-industry"
      options={sectors}
      sx={{ ml: 0, mt: 1, mr: 2, width: { xs: '97%', sm: '80%' } }}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      size="small"
      onChange={handleChangeIndustry}
      onFocus={handleOpen}
      onBlur={handleClose}
      onClose={handleClose}
      onOpen={handleOpen}
      open={search.industry.openOptions}
      value={defaultValue}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            p: 0,
            m: 0,
            cursor: option.sector_ref ? 'pointer' : 'default',
            '&:hover': {
              backgroundColor: option.sector_ref ? 'lightgrey' : 'transparent',
            },
            pointerEvents: option.sector_ref ? 'auto' : 'none',
          }}
          {...props}
        >
          {option.sector_ref !== undefined ? (
            <span style={{ fontSize: 12 }}>{option.label}</span>
          ) : (
            <b>{option.label}</b>
          )}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Sectors and Industries" />}
    />
  );
}
