/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useContext, useEffect, useState } from 'react';
import './style.css';
import { useFetchDetectTerm } from 'src/Domain/UseCases/useFetchDetectTerm';
import { TermDataSourceImpl } from 'src/Data/DataSources/Term/TermDataSource';
import { TermRepositoryImpl } from 'src/Data/Repositories/TermRepositoryImpl';
import { SearchContext } from 'src/Presentation/context/SearchContext';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';
import AlertNotificationContext from 'src/Presentation/context/AlertNotificationContext';
import AlertNotification from 'src/Presentation/pages/components/AlertNotification';
import TermsMatched from './TermsMatched';

export default function SearchTerms() {
  const { search, setSearch } = useContext(SearchContext);
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);
  const { setAlertNotification } = useContext(AlertNotificationContext);
  const [inputText, setInputText] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [termNotFound, setTermNotFound] = useState<boolean>(false);

  const { termDetected, isFetchDetectTermLoading } = useFetchDetectTerm(
    searchTerm,
    new TermRepositoryImpl(new TermDataSourceImpl())
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
    setTermNotFound(false);
  };

  const handleBackspaceDelete = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputText.length === 0 && (event.key === 'Backspace' || event.key === 'Delete')) {
      console.log('clean');
    }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };
  const handleClick = () => {
    if (inputText && !selectedTerms.includes(inputText)) {
      setSearchTerm(inputText);
    } else if (inputText) {
      setAlertNotification({
        showAlert: true,
        message: 'The term is already selected',
        severity: 'warning',
      });
    }
  };

  useEffect(() => {
    if (searchTerm !== '' && termDetected) {
      addTermToSelectedTerms();
    }
  }, [isFetchDetectTermLoading]);

  const addTermToSelectedTerms = () => {
    if (termDetected?.domain === 'industry_term') {
      if (!selectedTerms.includes(termDetected.stored_value))
        setSelectedTerms((prevState) => [...prevState, termDetected.stored_value]);
      setSearch((prevState) => ({
        ...prevState,
        terms: [...prevState.terms, termDetected.stored_value],
      }));
    } else {
      setTermNotFound(true);
      setAlertNotification({
        showAlert: true,
        message: 'Term not found',
        severity: 'error',
      });
    }
    setInputText('');
    setSearchTerm('');
  };

  return (
    <>
      <AlertNotification top={1} />
      <Box sx={{ display: 'flex', mt: 1, ml: 0, mr: 2, flexDirection: 'column', flexGrow: 0 }}>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Find terms"
          data-cy="input-term"
          sx={{ width: { xs: '100%', sm: '80%' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ padding: 0 }} position="end">
                <IconButton
                  type="button"
                  data-cy="button-input-term"
                  sx={{
                    cursor: inputText.length >= 2 ? 'pointer' : 'not-allowed',
                  }}
                  aria-label="Search"
                  disabled={inputText.length < 2}
                  onClick={handleClick}
                >
                  {termDetected && isFetchDetectTermLoading ? (
                    <CircularProgress size={24} />
                  ) : termNotFound ? (
                    <AddIcon />
                  ) : (
                    <AddIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
          onKeyUp={handleBackspaceDelete}
          onKeyDown={handleEnterKeyPress}
          disabled={search.industry.value === ''}
          value={inputText}
        />
        <TermsMatched />
      </Box>
    </>
  );
}
