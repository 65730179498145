/* eslint-disable @typescript-eslint/no-shadow */
import { Box, Chip, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SelectedTermsContext } from 'src/Presentation/context/SelectedTermContexts';
import { useContext, useEffect } from 'react';
import { SearchContext } from 'src/Presentation/context/SearchContext';

export default function TermsMatched() {
  const { selectedTerms, setSelectedTerms } = useContext(SelectedTermsContext);
  const { search, setSearch } = useContext(SearchContext);

  const handleDeleteChip = (chipToDelete: string) => () => {
    setSelectedTerms((prevState) => {
      const updatedTerms = prevState.filter((chip) => chip !== chipToDelete);
      setSearch((prevState) => ({
        ...prevState,
        terms: updatedTerms,
      }));
      return updatedTerms;
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    setSelectedTerms((prevState) => search.terms);
  }, []);

  return (
    <Box sx={{ mt: 2, overflow: 'hidden' }}>
      {selectedTerms.length > 0 && (
        <Stack
          data-cy="list-terms"
          direction="row"
          sx={{
            flexWrap: 'wrap',
            maxWidth: '100%',
          }}
        >
          {selectedTerms.map((term, index) => (
            <Chip
              key={index}
              label={term}
              color="primary"
              data-cy="selected-terms"
              onDelete={handleDeleteChip(term)}
              sx={{ mr: 1, mb: 1 }}
              deleteIcon={
                <IconButton size="small" aria-label="delete">
                  <CloseIcon sx={{ fontSize: 15 }} />
                </IconButton>
              }
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}
