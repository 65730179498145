import { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';

interface termsByIndustry {
  industryID: string;
  label: string;
  terms: string[];
}

interface IndustryType {
  label: string;
  sector_ref: string;
  value: string;
  openOptions: boolean;
  modalOpened: boolean;
  terms: termsByIndustry[];
}
interface SearchType {
  industry: IndustryType;
  activeTab: string | undefined;
  subTabActive: string | undefined;
  terms: string[];
  page: number;
}

interface SearchContextType {
  search: SearchType;
  setSearch: Dispatch<SetStateAction<SearchType>>;
}
const defaultValue: SearchContextType = {
  search: {
    industry: {
      label: '',
      sector_ref: '',
      value: '',
      openOptions: false,
      modalOpened: true,
      terms: [],
    },
    terms: [],
    activeTab: undefined,
    subTabActive: undefined,
    page: 1,
  },
  setSearch: () => {},
};

export const SearchContext = createContext(defaultValue);

interface SearchContextProviderProps {
  children: React.ReactNode;
}

export const SearchContextProvider = (props: SearchContextProviderProps) => {
  const { children } = props;
  const [searchState, setSearchState] = useState<SearchType>(defaultValue.search);

  const contextValue = useMemo(
    () => ({
      search: searchState,
      setSearch: setSearchState,
    }),
    [searchState]
  );

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
};
